import { Flex, FlexProps, Spinner, SpinnerProps, Text } from '@chakra-ui/react'

import { useEffect, useState } from 'react'

export function DelayedSpinner({
  delayMs = 300,
  mt = 20,
  message = '',
  size = 'xl',
  alignItems = 'center',
  justifyContent = 'center',
  color = 'blue.500',
  ...props
}: {
  delayMs?: number
  mt?: number
  message?: string
  size?: SpinnerProps['size']
  color?: SpinnerProps['color']
} & Pick<FlexProps, 'alignItems' | 'justifyContent'>): JSX.Element {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delayMs)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (show) {
    return (
      <Flex alignItems={alignItems} justifyContent={justifyContent} flexDirection="column" {...props}>
        <Spinner speed="0.65s" mt={mt} size={size} color={color} />
        {message && (
          <Text pt="10" fontSize="xl" textAlign="center" as="span">
            {message}
          </Text>
        )}
      </Flex>
    )
  }

  return <></>
}
