import { actions, connect, kea, listeners, path, reducers } from 'kea'
import { combineUrl, router, urlToAction } from 'kea-router'
import UrlPattern from 'url-pattern'

import { LazyExoticComponent, lazy } from 'react'

import { Params } from '../Types'
import { analyticsLogic } from './analyticsLogic'
import { authLogic } from './authLogic'
import type { sceneLogicType } from './sceneLogicType'

export enum Scene {
  login = 'login',
  createAccount = 'create-account',
  error404 = 'error404',
  'settings/recommendations' = 'settings/recommendations',
  'settings/distribution-centers' = 'settings/distribution-centers',
  'settings/shipping-methods' = 'settings/shipping-methods',
  'settings/packaging' = 'settings/packaging',
  'settings/coolant-blocks' = 'settings/coolant-blocks',
  'settings/products' = 'settings/products',
  'settings/carrier-settings' = 'settings/carrier-settings',
  shipmentImportsList = 'shipment-imports-list',
  importShipments = 'import-shipments',
  readSensor = 'read-sensor',
  integrations = 'integrations',
  shipments = 'shipments',
  sensors = 'sensors',
  simulationLab = 'simulation-lab',
  bulkRecommendationConfigs = 'bulk-recommendation-configs',
  insights = 'insights',
  buySensors = 'buy-sensors',
  buySensorsSuccess = 'buy-sensors-success',
  shopifyBillingRedirect = 'shopify-billing-redirect',
  stripeBillingRedirect = 'stripe-billing-redirect',
  copilot = 'copilot',
  'copilot-demo' = 'copilot-demo',
  'copilot-demo/explanation' = 'copilot-demo/explanation',
  shopifyIntegrationAuth = 'shopify-integration-auth',
  shopifyIntegration = 'shopify-integration',
  shipStationIntegration = 'ship-station-integration',
  roiCalculator = 'roi-calculator',
  materialsForecasting = 'materials-forecasting',
}

export type SceneParams = Record<string, any> | undefined

export interface SceneData {
  component: LazyExoticComponent<(params: SceneParams) => JSX.Element>
  loginRequired: boolean
  hideSideBar?: boolean
  redirectUnauthenticatedTo?: Scene
}

export interface RedirectInfo {
  redirectTo: Scene
  preserveParams?: boolean
}

export const scenes: Record<Scene, SceneData> = {
  [Scene.error404]: { component: lazy(() => import('../../scenes/not-found/NotFound')), loginRequired: false },
  [Scene.createAccount]: {
    component: lazy(() => import('../../scenes/create-account/CreateAccount')),
    loginRequired: false,
    hideSideBar: true,
  },
  [Scene.stripeBillingRedirect]: {
    component: lazy(() => import('../../scenes/billing-redirects/StripeBillingRedirect')),
    loginRequired: false,
  },
  [Scene.shopifyBillingRedirect]: {
    component: lazy(() => import('../../scenes/billing-redirects/ShopifyBillingRedirect')),
    loginRequired: false,
  },
  [Scene.readSensor]: {
    component: lazy(() => import('../../scenes/read-sensor/ReadSensor')),
    loginRequired: false,
    hideSideBar: true,
  },
  [Scene.login]: { component: lazy(() => import('../../scenes/login/Login')), loginRequired: false, hideSideBar: true },
  'settings/recommendations': {
    component: lazy(() => import('../../scenes/settings/recommendations/RecommendationSettings')),
    loginRequired: true,
  },
  'settings/distribution-centers': {
    component: lazy(() => import('../../scenes/settings/distribution-centers/DistributionCenterSettings')),
    loginRequired: true,
  },
  'settings/shipping-methods': {
    component: lazy(() => import('../../scenes/settings/shipping-methods/ShippingMethodSettings')),
    loginRequired: true,
  },
  'settings/packaging': {
    component: lazy(() => import('../../scenes/settings/packaging/PackagingSettings')),
    loginRequired: true,
  },
  'settings/coolant-blocks': {
    component: lazy(() => import('../../scenes/settings/coolant/CoolantSettings')),
    loginRequired: true,
  },
  'settings/products': {
    component: lazy(() => import('../../scenes/settings/products/ProductSettings')),
    loginRequired: true,
  },
  [Scene['settings/carrier-settings']]: {
    component: lazy(() => import('../../scenes/settings/carrier-settings/CarrierSettings')),
    loginRequired: true,
  },
  [Scene.shipmentImportsList]: {
    component: lazy(() => import('../../scenes/shipment-imports-list/ShipmentImportsList')),
    loginRequired: true,
  },
  [Scene.importShipments]: {
    component: lazy(() => import('../../scenes/import-shipments/ImportShipments')),
    loginRequired: true,
  },
  [Scene.shopifyIntegration]: {
    component: lazy(() => import('../../scenes/integrations/ShopifyDetail')),
    loginRequired: true,
  },
  [Scene.shipStationIntegration]: {
    component: lazy(() => import('../../scenes/integrations/ShipStationDetail')),
    loginRequired: true,
  },
  [Scene.integrations]: {
    component: lazy(() => import('../../scenes/integrations/Integrations')),
    loginRequired: true,
  },
  [Scene.shopifyIntegrationAuth]: {
    component: lazy(() => import('../../scenes/integrations/ShopifyIntegrationAuth')),
    loginRequired: true,
    redirectUnauthenticatedTo: Scene.createAccount,
  },
  [Scene.shipments]: {
    component: lazy(() => import('../../scenes/shipments/Shipments')),
    loginRequired: true,
  },
  sensors: {
    component: lazy(() => import('../../scenes/sensors/Sensors')),
    loginRequired: true,
  },
  [Scene.simulationLab]: {
    component: lazy(() => import('../../scenes/simulation-lab/SimulationLabScene')),
    loginRequired: true,
  },
  [Scene.bulkRecommendationConfigs]: {
    component: lazy(() => import('../../scenes/bulk-recommendation-config/BulkRecommendationConfigs')),
    loginRequired: true,
  },
  [Scene.insights]: { component: lazy(() => import('../../scenes/insights/Insights')), loginRequired: true },
  [Scene.buySensors]: {
    component: lazy(() => import('../../scenes/buy-sensors/BuySensors')),
    loginRequired: true,
  },
  [Scene.buySensorsSuccess]: {
    component: lazy(() => import('../../scenes/buy-sensors/BuySensorsSuccess')),
    loginRequired: true,
  },
  [Scene.copilot]: {
    component: lazy(() => import('../../scenes/copilot/Copilot')),
    loginRequired: true,
  },
  [Scene['copilot-demo']]: {
    component: lazy(() => import('../../scenes/copilot/CopilotDemo')),
    loginRequired: true,
    hideSideBar: true,
  },
  [Scene['copilot-demo/explanation']]: {
    component: lazy(() => import('../../scenes/copilot/CopilotDemoOrgDetailExplanation')),
    loginRequired: true,
    hideSideBar: true,
  },
  [Scene.roiCalculator]: {
    component: lazy(() => import('../../scenes/roi-calculator/RoiCalculator')),
    loginRequired: false,
    hideSideBar: true,
  },
  [Scene.materialsForecasting]: {
    loginRequired: true,
    component: lazy(() => import('../../scenes/materials-forecasts/MaterialsForecasting')),
  },
}

export const routesToScenes: Record<string, Scene> = {
  '/login': Scene.login,
  '/create-account': Scene.createAccount,
  '/billing/shopify-redirect': Scene.shopifyBillingRedirect,
  '/billing/stripe-redirect': Scene.stripeBillingRedirect,
  '/settings/recommendations': Scene['settings/recommendations'],
  '/settings/distribution-centers': Scene['settings/distribution-centers'],
  '/settings/shipping-methods': Scene['settings/shipping-methods'],
  '/settings/packaging': Scene['settings/packaging'],
  '/settings/coolant-blocks': Scene['settings/coolant-blocks'],
  '/settings/products': Scene['settings/products'],
  '/settings/carrier-settings': Scene['settings/carrier-settings'],
  '/import-shipments': Scene.importShipments,
  '/shipment-imports': Scene.shipmentImportsList,
  '/read-sensor': Scene.readSensor,
  '/integrations/ship-station(/:tab)': Scene.shipStationIntegration,
  '/integrations/shopify(/:tab)': Scene.shopifyIntegration,
  '/integrations': Scene.integrations,
  '/auth/shopify/callback': Scene.shopifyIntegrationAuth,
  '/shipments': Scene.shipments,
  '/simulation-lab': Scene.simulationLab,
  '/bulk-recommendation-configs(/:uuid)': Scene.bulkRecommendationConfigs,
  '/insights': Scene.insights,
  '/buy-sensors': Scene.buySensors,
  '/buy-sensors/success': Scene.buySensorsSuccess,
  '/copilot': Scene.copilot,
  '/copilot-demo': Scene['copilot-demo'],
  '/copilot-demo/explanation': Scene['copilot-demo/explanation'],
  '/roi-calculator': Scene.roiCalculator,
  '/sensors': Scene.sensors,
  '/materials-forecasting': Scene.materialsForecasting,
}

type ScenesToUrlMapping = Record<Scene, (segmentParams?: Record<string, string>) => string>

export const scenesToURLs: ScenesToUrlMapping = {
  ...Object.entries(routesToScenes).reduce((acc, [route, scene]) => {
    acc[scene] = (segmentParams) => new UrlPattern(route).stringify(segmentParams)
    return acc
  }, {} as ScenesToUrlMapping),
  // oddball 404 scene doesn't belong in routesToScenes
  [Scene.error404]: (): string => '/404',
}

export const sceneLogic = kea<sceneLogicType>([
  path(['lib', 'logics', 'sceneLogic', 'sceneLogic']),
  connect({
    values: [authLogic, ['isAuthenticated', 'iFrameDemoAuthCredentials']],
    actions: [analyticsLogic, ['capture'], authLogic, ['setIFrameDemoAuthCredentials']],
  }),
  actions({
    setScene: (scene: Scene, params: object) => ({ scene, params }),
  }),
  reducers({
    scene: [
      Scene.shipments as Scene,
      {
        setScene: (_, { scene }) => scene,
      },
    ],
    params: [
      {},
      {
        setScene: (_, { params }) => params || {},
      },
    ],
  }),
  listeners(({ actions }) => ({
    setScene: () => {
      actions.capture('$pageview')
    },
  })),
  urlToAction(({ actions, values }) => {
    const checkAuthAndNavigateToScene = (scene: Scene, params: Params): void => {
      const sceneData = scenes[scene]
      const apiKey: string = router.values.searchParams.apiKey
      if (scene.startsWith('copilot-demo') && apiKey) {
        actions.setIFrameDemoAuthCredentials(apiKey)
      }
      if (sceneData.loginRequired && !values.isAuthenticated) {
        const encodedRedirectTo = encodeURIComponent(
          combineUrl(
            router.values.currentLocation.pathname,
            router.values.currentLocation.searchParams,
            router.values.currentLocation.hashParams
          ).url
        )
        const redirectToScene = sceneData.redirectUnauthenticatedTo ?? Scene.login
        const newBaseUrl = scenesToURLs[redirectToScene]()
        const newUrl = combineUrl(newBaseUrl, { redirectTo: encodedRedirectTo }).url
        router.actions.push(newUrl)
      } else {
        actions.setScene(scene, params)
      }
    }

    const redirectMappings: Record<string, RedirectInfo> = {
      '/': { redirectTo: Scene.copilot, preserveParams: true },
    }

    const routeToSceneMapping: Record<
      string,
      (
        params: Params,
        searchParams: Params,
        hashParams: Params,
        payload: {
          method: string
        }
      ) => any
    > = {}

    Object.entries(routesToScenes).forEach(([route, scene]) => {
      routeToSceneMapping[route] = (params: Record<string, string>) => checkAuthAndNavigateToScene(scene, params)
    })

    for (const path of Object.keys(redirectMappings)) {
      routeToSceneMapping[path] = (_params, searchParams, hashParams) => {
        const redirect = redirectMappings[path]
        let newUrl = scenesToURLs[redirect.redirectTo]()
        if (redirect.preserveParams) {
          newUrl = combineUrl(newUrl, searchParams, hashParams).url
        }
        router.actions.replace(newUrl)
      }
    }

    routeToSceneMapping['/*'] = (params: Record<string, string>) => checkAuthAndNavigateToScene(Scene.error404, params)
    return routeToSceneMapping
  }),
])
